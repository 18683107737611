import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { StyledButton } from "./index";

function AssignWalletModal({ wallets, deals, assignWallet }) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    walletId: wallets[0]?.id,
    dealId: deals[0]?.id,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = () => {
    assignWallet(data.walletId, data.dealId);
    setShow(false);
  };

  const onSelect = (e) => {

    const { value, name } = e.target;

    if (name === "wallets") {
      setData({ ...data, walletId: Number(value) });
    } else {
      setData({ ...data, dealId: Number(value) });
    }
  };

  return (
    <>
      <StyledButton variant="primary" onClick={handleShow}>
        Assign Wallet
      </StyledButton>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Wallet</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={onSelect}
                name="wallets"
              >
                {wallets?.map((wallet) => {
                  return (
                    <option value={wallet.id} key={wallet.id}>
                      {wallet.name} - {wallet.address}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Deal</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={onSelect}
                name="deals"
              >
                {deals?.map((deal) => {
                  return (
                    <option value={deal.id} key={deal.id}>
                      {deal.name} - {deal.address}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledButton variant="secondary" onClick={handleClose}>
            Close
          </StyledButton>
          <StyledButton variant="primary" onClick={handleSave}>
            Save Changes
          </StyledButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AssignWalletModal;
