import { Modal, Form } from 'react-bootstrap'
import { StyledButton } from './index'
import React, { useState, useEffect } from 'react'
import { getSeedWallets } from '../services/apiService'
import { utils, BigNumber } from 'ethers'

function AddEditOldDealModal({ onSave, seeds, show, handleClose, handleShow, setAlertData, dealToEdit }) {
  const [deal, setDeal] = useState({
    name: '',
    allocationModel: 'prorata',
    recieveWalletSeedId: seeds[0] ? seeds[0].id : null,
    distributionModel: 'claimer',
    imageUrl: '',
    tokenAddress: '',
    dealWallet: '',
    walletPath: '',
    blockchainId: '1',
    totalSent: ''
  })
  const [pathModel, setPathModel] = useState('metamask')
  const [recieveWallets, setRecieveWallets] = useState([])
  const [recieveWalletsLoading, setRecieveWalletsLodaing] = useState(false);
  const isEdit = dealToEdit || dealToEdit === null

  const getPathModel = (path) => {
    if (!path) {
      return 'N/A';
    }
      
    // eslint-disable-next-line
    const [_, secondPart] = path.split("m/44'/60'/")
    const secondPartChunks = secondPart.split('/')
    if (secondPartChunks.length === 2) return 'mycrypto'
    if (secondPartChunks[0] !== "0'") return 'trezor'
    return 'metamask'
  }

  useEffect(() => {
    if (!dealToEdit || !show) return

    const {
      name,
      allocationModel,
      recieveWalletSeedId,
      blockchainId,
      totalAmount,
      distributionModel,
      imageUrl,
      tokenAddress,
      dealWallet,
      walletPath,
      id
    } = dealToEdit

    setPathModel(getPathModel(walletPath))

    setDeal({
      id,
      name,
      allocationModel,
      recieveWalletSeedId,
      distributionModel,
      imageUrl,
      tokenAddress,
      dealWallet,
      recieveWalletPath: walletPath,
      blockchainId: blockchainId?.toString(),
      totalSent: totalAmount
    })
  }, [dealToEdit, seeds, show])

  const onInputChange = (e) => {
    const { value, name } = e.target

    const newDeal = { ...deal }

    if (name === 'recieveWalletPath') {
      const recieveWallet = recieveWallets.find((w) => w.path === value)
      newDeal.recieveWalletAddress = recieveWallet.address
    }

    if (name === 'pathModel') {
      setPathModel(value)
      return
    }

    newDeal[name] = value

    setDeal(newDeal)
  }

  useEffect(() => {
    if (!show) return
    const loadSeedWallets = async () => {
      try {
        setRecieveWalletsLodaing(true)
        const seedWallets = await getSeedWallets(pathModel, deal.recieveWalletSeedId)
        setRecieveWallets(seedWallets.data)
        setDeal(prev => ({ ...prev, recieveWalletPath: deal.recieveWalletPath || seedWallets.data[0].path, recieveWalletAddress: seedWallets.data[0].address }))
      } finally {
        setRecieveWalletsLodaing(false)
      }
    }

    if (deal.recieveWalletSeedId && pathModel) {
      loadSeedWallets()
    }
    // eslint-disable-next-line
  }, [deal.recieveWalletSeedId, pathModel, show])

  useEffect(() => {
    if (deal.contributions) {
      try {
        const contributions = deal.contributions.split('\n').map((x) => x.split(';'))
        console.log(contributions)
        const sumContributions = contributions.reduce((a, b) => a.add(utils.parseUnits(b[1].replace(/,/g, ''), 6)), BigNumber.from(0))
        const newDeal = { ...deal, totalSent: utils.formatUnits(sumContributions, 6) }
        setDeal(newDeal)
      } catch (error) {
        console.error(error)
        setAlertData({
          variant: 'danger',
          text: 'Could not parse contributions, check format'
        })
      }
    }
    // eslint-disable-next-line
  }, [deal.contributions])

  
  return (
    <>
      <StyledButton variant="primary" onClick={handleShow}>
        {isEdit ? 'Edit' : 'New Old Deal'}
      </StyledButton>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? 'Edit' : 'New'} Deal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control name="name" value={deal.name} onChange={onInputChange} type="text" placeholder="Enter name" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Contributions</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                name="contributions"
                onChange={onInputChange}
                type="text"
                placeholder="Enter contributions"
                disabled={isEdit}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image Url</Form.Label>
              <Form.Control name="imageUrl" onChange={onInputChange} value={deal.imageUrl} type="text" placeholder="Enter image url" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Token Address</Form.Label>
              <Form.Control
                name="tokenAddress"
                onChange={onInputChange}
                value={deal.tokenAddress}
                type="text"
                placeholder="Enter token address"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Chain</Form.Label>
              <Form.Select onChange={onInputChange} name="blockchainId" value={deal.blockchainId}>
                <option value="1" key="1">
                  Ethereum
                </option>
                <option value="4" key="4">
                  BSC
                </option>
                <option value="6" key="6">
                  Polygon
                </option>
                <option value="2" key="2">
                  Rinkeby
                </option>
                <option value="5" key="5">
                  BSC Testnet
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Deal Wallet</Form.Label>
              <Form.Control
                name="dealWallet"
                onChange={onInputChange}
                value={deal.dealWallet}
                type="text"
                placeholder="Enter deal wallet"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Recieve Wallet Seed</Form.Label>
              <Form.Select
                disabled={recieveWalletsLoading}
                onChange={onInputChange}
                value={deal.recieveWalletSeedId}
                aria-label="Default select example"
                name="recieveWalletSeedId"
              >
                {seeds.map((seed) => (
                  <option key={seed.id.toString()} value={seed.id}>
                    {seed.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Path Model</Form.Label>
              <Form.Select onChange={onInputChange} name="pathModel" value={pathModel} disabled={recieveWalletsLoading} >
                <option value="trezor" key="1">
                  Trezor (m/44'/60'/x'/0/0)
                </option>
                <option value="metamask" key="2">
                  Metamask (m/44'/60'/0'/0/x)
                </option>
                <option value="mycrypto" key="3">
                  MyCrypto (m/44'/60'/x'/0')
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Recieve Wallet</Form.Label>
              <Form.Select disabled={recieveWalletsLoading} aria-label="Default select example" onChange={onInputChange} value={recieveWalletsLoading || deal.recieveWalletPath} name="recieveWalletPath">
                {recieveWalletsLoading && <option>Loading...</option>}
                {recieveWallets?.map((wallet) => {
                  return (
                    <option value={wallet.path} key={wallet.path}>
                      {wallet.path} - {wallet.address}
                    </option>
                  )
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Allocation model</Form.Label>
              <Form.Select value={deal.allocationModel} onChange={onInputChange} aria-label="Default select example" name="allocationModel">
                <option value="prorata">ProRata</option>
                <option value="level">Level</option>
                <option value="nolock">No Lock</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Distribution model</Form.Label>
              <Form.Select
                value={deal.distributionModel}
                onChange={onInputChange}
                aria-label="Default select example"
                name="distributionModel"
              >
                <option value="claimer">claimer</option>
                <option value="disperse">disperse</option>
                <option value="custom">custom</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Total Sent</Form.Label>
              <Form.Control name="totalSent" onChange={onInputChange} type="text" value={deal.totalSent} placeholder="Enter total amount" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledButton
            variant="primary"
            onClick={() => {
              onSave(deal)
            }}
          >
            {isEdit ? 'Edit' : 'Save as Draft'}
          </StyledButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddEditOldDealModal
