import styled from "styled-components";
import { Button, Tabs, Form } from "react-bootstrap";

export const Header = styled.header`
  background-color: #282c34;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
`;

export const Body = styled.div`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 0;
  font-size: 16px;
  min-height: 100vh;
`;

export const Image = styled.img`
  height: 30px;
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 10px;
`;

export const StyledButton = styled(Button)`
  color: #282c34;
  background-color: white;

  ${(props) => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }
`;

export const StyledTabs = styled(Tabs)`
  background: #282c34;
  padding-bottom: 1px;
  .nav-link {
    color: #fff;
    background: #282c34;
  }
`;

export const PathHolder = styled(Form.Group)`
  position: relative;
`;
