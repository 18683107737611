import { Modal, Form } from "react-bootstrap";
import { StyledButton } from "./index";
import React, { useState } from "react";

function SeedModal({ createNewSeed }) {
  const [show, setShow] = useState(false);
  const [seed, setSeed] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onInputChange = (e) => {
    const { value, name } = e.target;
    console.log("oninputchange", value, name);

    setSeed({ ...seed, [name]: value });
  };

  return (
    <>
      <StyledButton variant="primary" onClick={handleShow}>
        New Seed
      </StyledButton>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Seed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" >
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                onChange={onInputChange}
                type="text"
                placeholder="Enter name"
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Encrypted</Form.Label>
              <Form.Control
                name="hash"
                onChange={onInputChange}
                type="text"
                placeholder="Enter encrypted mnemonic"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledButton variant="secondary" onClick={handleClose}>
            Close
          </StyledButton>
          <StyledButton
            variant="primary"
            onClick={() => {
              createNewSeed(seed);
            }}
          >
            Save Changes
          </StyledButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SeedModal;
