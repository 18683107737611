import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import { postDecryptSeed, postUnlockWallet } from '../services/apiService'
import PasswordModal from './PasswordModal'

const SeedsAndWalletsTable = ({ seeds, encryptedWallets, setAlertData, loadSeeds, loadEncryptedWallets }) => {
  const [passwordModal, setPasswordModal] = useState(null)

  const decryptSeed = async ({ id, password }) => {
    try {
      await postDecryptSeed({ seedId: id, password })
      loadSeeds()
      setPasswordModal(null)
      setAlertData({
        variant: 'success',
        text: 'Decrypted seed successfully'
      })
    } catch (err) {
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const unlockWallet = async ({ id, password }) => {
    try {
      await postUnlockWallet(id, password)
      loadEncryptedWallets()
      setPasswordModal(null)
      setAlertData({
        variant: 'success',
        text: 'Unlocked wallet successfully'
      })
    } catch (err) {
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  return (
    <>
      <PasswordModal
        isSeed={passwordModal?.isSeed}
        id={passwordModal?.id}
        onSave={passwordModal?.isSeed ? decryptSeed : unlockWallet}
        onClose={() => setPasswordModal(null)}
        show={!!passwordModal}
      />
      <h2>Seeds</h2>
      <Table variant="dark">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Unlocked</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {seeds?.map((seed) => (
            <tr key={seed.id}>
              <td>{seed.id}</td>
              <td>{seed.name}</td>
              <td>{seed.unlocked.toString()}</td>
              <td>
                <button onClick={() => setPasswordModal({ isSeed: true, id: seed.id })} disabled={seed.unlocked}>
                  Unlock
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <h2>Wallets</h2>
      <Table variant="dark">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Unlocked</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {encryptedWallets?.map((wallet) => (
            <tr key={wallet.id}>
              <td>{wallet.id}</td>
              <td>{wallet.name}</td>
              <td>{wallet.unlocked.toString()}</td>
              <td>
                <button disabled={wallet.unlocked} onClick={() => setPasswordModal({ isSeed: false, id: wallet.name })}>
                  Unlock
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default SeedsAndWalletsTable
