import axios from "axios";

const baseUrlApi =
  process.env.REACT_APP_BASE_URL_API || "https://api-dev.blackdragon.io";
const baseUrlDM =
  process.env.REACT_APP_BASE_URL_DM || "https://dm-api-dev.blackdragon.io";

const apiGet = (url, isBDApi = true) => {
  const authData = isBDApi ? JSON.parse(localStorage.getItem("tokenBDApi")) : JSON.parse(localStorage.getItem("tokenDM"));

  return axios.get(url, {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${authData.token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

const apiPostNoBearer = (url, data) => {
  return axios(url, {
    method: "POST",
    crossDomain: true,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

const apiPost = (url, data, isBDApi = true) => {
  const authData = isBDApi ? JSON.parse(localStorage.getItem("tokenBDApi")) : JSON.parse(localStorage.getItem("tokenDM"));

  return axios(url, {
    method: "POST",
    crossDomain: true,
    data,
    headers: {
      Authorization: `Bearer ${authData.token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

const apiPut = async (url, data) => {
  return await axios.put(url, data, {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("tokenBDApi")).token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

const apiDel = async (url) => {
  return axios.delete(url, {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("tokenBDApi")).token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAllOldDeals = async () => {
  const response = await apiGet(`${baseUrlApi}/api/old-deal`);

  return response.data;
};

export const postAuthTokenApi = async (message) => {
  return apiPostNoBearer(`${baseUrlApi}/api/auth/token`, { message });
};

export const postAuthTokenDM = async (message) => {
  return apiPostNoBearer(`${baseUrlDM}/api/auth/token`, { message });
};

export const getOldDeals = async () => {
  return apiGet(`${baseUrlApi}/api/admin-old-deal`);
};

export const getOldDeal = async (id) => {
  return apiGet(`${baseUrlApi}/api/old-deal/${id}`);
};

export const deleteOldDeal = async (id) => {
  return apiDel(`${baseUrlApi}/api/old-deal/${id}`);
};

export const createOldDeal = async (deal) => {
  return apiPost(`${baseUrlApi}/api/old-deal`, deal);
};

export const updateOldDealDetails = async (deal) => {
  return apiPut(`${baseUrlApi}/api/old-deal-details/${deal.id}`, deal);
};

export const updateOldDeal = async (deal) => {
  return apiPut(`${baseUrlApi}/api/old-deal/${deal.id}`, deal);
};

export const publishOldDeal = async (dealId, isDraft) => {
  return apiPost(`${baseUrlApi}/api/old-deal/${dealId}/publish`, { isDraft });
};

export const checkForSinners = async (deal) => {
  return apiPost(`${baseUrlApi}/api/check-old-deal`, deal);
};

export const getDeals = async () => {
  return apiGet(`${baseUrlApi}/api/deal/admin/admin-deal`);
};

export const assignWallet = async (data) => {
  return apiPost(`${baseUrlDM}/api/assign-wallet`, data, false);
};

export const getAssignedWallets = async () => {
  return apiGet(`${baseUrlDM}/api/assign-wallet`, null, false);
};

export const getSeeds = async () => {
  return apiGet(`${baseUrlDM}/api/seed`, false);
};

export const getWallets = async () => {
  return apiGet(`${baseUrlDM}/api/wallet`, false);
};

export const getJobs = async (name, limit = 1000, offset = 0) => {
  return apiGet(`${baseUrlDM}/api/job?limit=${limit}&offset=${offset}&name=${name}`, false);
};

export const getSeedWallets = async (pathModel, seedId) => {
  return apiGet(`${baseUrlDM}/api/seed/${seedId}/wallet?pathModel=${pathModel}`, false)
}

export const postSeed = async (seed) => {
  return apiPost(`${baseUrlDM}/api/seed`, seed, false);
};

export const postWallet = async (wallet) => {
  return apiPost(`${baseUrlDM}/api/wallet`, wallet, false);
};

export const postDecryptSeed = async (seed) => {
  return apiPost(`${baseUrlDM}/api/seed-decrypt`, seed, false);
};

export const postUnlockWallet = async (wallet, password) => {
  return apiPost(`${baseUrlDM}/api/wallet-decrypt`, { walletName: wallet, password }, false);
};

export const getEncryptedWallets = async () => {
  return apiGet(`${baseUrlDM}/api/encrypted-wallet`, false);
};
