import { Modal, Form } from 'react-bootstrap'
import { StyledButton } from './index'
import React, { useState } from 'react'

function PasswordModal({ isSeed, id, onSave, onClose, show }) {
  const [password, setPassword] = useState('')

  const onPasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleSave = (e) => {
    e.preventDefault()
    onSave({ id, isSeed, password })
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Decrypt {isSeed ? 'seed' : 'wallet'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSave}>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control name="password" onChange={onPasswordChange} type="password" placeholder="Enter password" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <StyledButton variant="secondary" onClick={onClose}>
          Close
        </StyledButton>
        <StyledButton variant="primary" onClick={handleSave}>
          Decrypt
        </StyledButton>
      </Modal.Footer>
    </Modal>
  )
}

export default PasswordModal
