import React, { useEffect, useState } from 'react'
import { Body, StyledButton, Image, StyledTabs } from './components'
import useWeb3Modal from './hooks/useWeb3Modal'
import { Container, Col, Row, Table, Alert, Tab } from 'react-bootstrap'
import {
  postAuthTokenApi,
  postAuthTokenDM,
  getOldDeals,
  getOldDeal,
  deleteOldDeal,
  createOldDeal,
  updateOldDealDetails,
  checkForSinners,
  updateOldDeal,
  postSeed,
  getSeeds,
  postWallet,
  getDeals,
  getWallets,
  assignWallet,
  publishOldDeal,
  getEncryptedWallets
} from './services/apiService'
import ContributionRow from './components/ContributionRow'
import AddEditOldDealModal from './components/AddEditOldDealModal'
import SeedModal from './components/SeedModal'
import WalletModal from './components/WalletModal'
import WalletsTable from './components/WalletsTable'
import SeedsAndWalletsTable from './components/SeedsAndWalletsTable'
import AssignedWalletsTable from './components/AssignedWalletsTable'
import AssignWalletModal from './components/AssignWalletModal'
import JobsTable from './components/JobsTable'

function ConnectWalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  return (
    <StyledButton
      onClick={() => {
        if (!provider) {
          loadWeb3Modal()
        } else {
          logoutOfWeb3Modal()
        }
      }}
    >
      {!provider ? 'Connect Wallet' : 'Disconnect Wallet'}
    </StyledButton>
  )
}

function SignInButton({ token, loadToken, apiName }) {
  return (
    <StyledButton
      onClick={() => {
        loadToken()
      }}
    >
      {!token ? `Sign In ${apiName}` : `Resign In ${apiName}`}
    </StyledButton>
  )
}

const adminWallet = process.env.REACT_APP_ADMIN_WALLET
const jobManagerWallet = process.env.REACT_APP_JOB_MANAGER_WALLET

function App() {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal()
  const [tokenBDApi, setTokenBDApi] = useState(null)
  const [tokenDM, setTokenDM] = useState(null)
  const [dealData, setDealData] = useState([])
  const [seeds, setSeeds] = useState([])
  const [encryptedWallets, setEncryptedWallets] = useState([])
  const [connectedWallet, setConnectedWallet] = useState(null)
  const [wallets, setWallets] = useState([])
  const [assignableWallets, setAssignableWallets] = useState([])
  const [assignableDeals, setAssignableDeals] = useState([])
  const [activeDeal, setActiveDeal] = useState(null)
  const [contributions, setContributions] = useState([])
  const [alertData, setAlertData] = useState(null)
  const [show, setShow] = useState(false)
  const [dealToEdit, setDealToEdit] = useState(null)

  useEffect(() => {
    if (alertData) {
      setTimeout(() => {
        setAlertData(null)
      }, 3000)
    }
  }, [alertData])

  const loadTokenBDApi = async () => {
    let signedMessage
    try {
      signedMessage = await provider.getSigner().signMessage('Enter The BlackDragon')
    } catch (err) {
      setAlertData({
        variant: 'danger',
        text: err.message
      })
      return
    }
    try {
      const response = await postAuthTokenApi(signedMessage)
      const token = {
        token: response.data.token,
        exp: response.data.exp,
        wallet: connectedWallet
      }

      localStorage.setItem('tokenBDApi', JSON.stringify(token))
      setTokenBDApi(token)

      setAlertData({
        variant: 'success',
        text: 'Signed in successfully'
      })
    } catch (err) {
      console.log(err.response)
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const loadTokenDM = async () => {
    let signedMessage
    try {
      signedMessage = await provider.getSigner().signMessage('Enter The BlackDragon Lair')
    } catch (err) {
      setAlertData({
        variant: 'danger',
        text: err.message
      })
      return
    }
    try {
      const response = await postAuthTokenDM(signedMessage)
      console.log('api/auth/token response', response.status, response.data)

      const token = {
        token: response.data.token,
        exp: response.data.exp,
        wallet: connectedWallet
      }

      localStorage.setItem('tokenDM', JSON.stringify(token))
      setTokenDM(token)

      setAlertData({
        variant: 'success',
        text: 'Signed in successfully'
      })
    } catch (err) {
      console.log(err.response)
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const loadSeeds = async () => {
    try {
      const seedsApi = await getSeeds()
      console.log('setseeds', seedsApi.data)
      setSeeds(seedsApi.data)
    } catch (error) {
      console.log(error)
      if (error.response?.status === 401) {
        setAlertData({
          variant: 'danger',
          text: 'YOU DONT HAVE ACCESS MORTAL'
        })
      }
    }
  }
  const loadEncryptedWallets = async () => {
    try {
      const encryptedWalletsApi = await getEncryptedWallets()
      setEncryptedWallets(encryptedWalletsApi.data)
    } catch (error) {
      console.log(error)
      if (error.response?.status === 401) {
        setAlertData({
          variant: 'danger',
          text: 'YOU DONT HAVE ACCESS MORTAL'
        })
      }
    }
  }

  const deleteDeal = async () => {
    try {
      await deleteOldDeal(activeDeal.id)
      const newDeals = [...dealData.filter((d) => d.id !== activeDeal.id)]
      setActiveDeal(null)
      setContributions([])
      setDealData(newDeals)
      setAlertData({
        variant: 'success',
        text: 'Deleted successfully'
      })
    } catch (err) {
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const createNewDeal = async (deal) => {
    try {
      console.log('create deal ', deal)
      const response = await createOldDeal(deal)

      console.log(response)
      loadOldDeals()
      setAlertData({
        variant: 'success',
        text: 'Created successfully'
      })
      setShow(false)
    } catch (err) {
      console.log(err.response)
      setAlertData({
        variant: 'danger',
        text: err.response.data
      })
    }
  }

  const createNewSeed = async (seed) => {
    try {
      console.log('create seed ', seed)
      const response = await postSeed(seed)

      console.log(response)
      setAlertData({
        variant: 'success',
        text: 'Created seed successfully'
      })
    } catch (err) {
      console.log(err.response.data.message)
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const createNewWallet = async (wallet) => {
    try {
      console.log('create wallet ', wallet)
      await postWallet(wallet)

      setAlertData({
        variant: 'success',
        text: 'Created wallet successfully'
      })
    } catch (err) {
      console.log(err.response.data.message)
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const onAssignWallet = async (walletId, dealId) => {
    try {
      await assignWallet({ walletId, dealId })
      setAlertData({
        variant: 'success',
        text: 'Wallet assigned successfully'
      })
    } catch (err) {
      console.error(err)
    }
  }

  const checkBdtBalances = async (deal) => {
    try {
      console.log('check for sinners ', deal)
      const response = await checkForSinners(deal)
      console.log(response)
      const _contributions = [...contributions]
      response.data.forEach((item) => {
        const element = _contributions.find((x) => x.wallet === item.wallet)
        if (element.valid !== item.valid) {
          element.valid = item.valid
        }
        if (element.reason !== item.reason) {
          element.reason = item.reason
        }
        setContributions(_contributions)
      })
      setAlertData({
        variant: 'success',
        text: 'Sinners updated'
      })
    } catch (err) {
      console.log(err)
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const updateDeal = async (deal, contributions) => {
    try {
      console.log('update deal ', deal)
      const request = {
        ...deal,
        contributions
      }
      const response = await updateOldDeal(request)
      console.log(response)
      setAlertData({
        variant: 'success',
        text: 'Updated successfully'
      })
    } catch (err) {
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const publishDeal = async (deal) => {
    try {
      console.log('publish deal ', deal)
      const response = await publishOldDeal(deal.id, !deal.isDraft)
      console.log(response)

      const newDeal = { ...deal, isDraft: !deal.isDraft }
      setActiveDeal(newDeal)

      const newDeals = [...dealData]
      const dealIdx = newDeals.findIndex((x) => x.id === deal.id)
      if (dealIdx !== -1) {
        newDeals[dealIdx].isDraft = !deal.isDraft
        setDealData(newDeals)
      }

      setAlertData({
        variant: 'success',
        text: 'Updated successfully'
      })
    } catch (err) {
      setAlertData({
        variant: 'danger',
        text: err.response.data.message
      })
    }
  }

  const shortEthereumAddress = (address) => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
  }

  const updateContribution = (contribution) => {
    const newContributions = [...contributions]

    const i = newContributions.findIndex((c) => c.id === contribution.id)
    if (i === -1) {
      // SHOULD NEVER HAPPEN
      console.log('could not find index of contribution - this should not happen')
      return
    }

    newContributions[i] = contribution

    setContributions(newContributions)
  }

  useEffect(() => {
    const loadConnectedWallet = async () => {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' })
      setConnectedWallet(accounts[0])
    }

    if (provider) {
      loadConnectedWallet()
    }
  }, [provider])

  const loadOldDeals = async () => {
    try {
      const oldDeals = await getOldDeals()
      console.log('all deals', oldDeals)
      setDealData(oldDeals.data)
    } catch (error) {
      console.log('loadolddeals error', error)
      if (error.response?.status === 401) {
        setAlertData({
          variant: 'danger',
          text: 'YOU DONT HAVE ACCESS MORTAL'
        })
      }
    }
  }
  const loadDeals = async () => {
    try {
      const dealsApi = await getDeals()
      setAssignableDeals(dealsApi.data.filter((x) => x.distributionWalletId === null).sort((x, y) => x.name.localeCompare(y.name)))
    } catch (error) {
      if (error.response?.status === 401) {
        setAlertData({
          variant: 'danger',
          text: 'YOU DONT HAVE ACCESS MORTAL'
        })
      }
    }
  }

  useEffect(() => {
    console.log('useeffect connectedWallet')
    if (connectedWallet) {
      const cachedTokenBD = JSON.parse(localStorage.getItem('tokenBDApi'))
      if (cachedTokenBD && cachedTokenBD.wallet === connectedWallet && cachedTokenBD.exp > Date.now() / 1000) {
        setTokenBDApi(cachedTokenBD)
      } else {
        localStorage.removeItem('tokenBDApi')
      }

      const cachedTokenDM = JSON.parse(localStorage.getItem('tokenDM'))
      if (cachedTokenDM && cachedTokenDM.wallet === connectedWallet && cachedTokenDM.exp > Date.now() / 1000) {
        setTokenDM(cachedTokenDM)
      } else {
        localStorage.removeItem('tokenDM')
      }
    }
  }, [connectedWallet])

  useEffect(() => {
    console.log('useeffect tokenBDAPI')
    if (tokenBDApi) {
      loadOldDeals()
      loadDeals()
    }
  }, [tokenBDApi])

  useEffect(() => {
    const loadWallets = async () => {
      const walletsApi = await getWallets()

      setWallets(walletsApi.data)
      setAssignableWallets(walletsApi.data.filter((x) => !x.deal).sort((x, y) => x.name.localeCompare(y.name)))
    }
    if (tokenDM) {
      loadSeeds()
      loadWallets()
      loadEncryptedWallets()
    }
  }, [tokenDM])

  useEffect(() => {
    console.log('useeffect activedeal')
    const loadOldDeal = async (id) => {
      const oldDeal = await getOldDeal(id)
      console.log('single deal', id, oldDeal)
      setContributions(oldDeal.data)
    }
    if (activeDeal) {
      loadOldDeal(activeDeal.id)
    }
  }, [activeDeal])

  const whitelistedWallets = [adminWallet.toLowerCase(), jobManagerWallet.toLowerCase()]

  if (!connectedWallet || !whitelistedWallets.includes(connectedWallet?.toLowerCase())) {
    return (
      <Body style={{ justifyContent: 'center' }}>
        {connectedWallet && <h3>Please connect with admin wallet</h3>}
        <ConnectWalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
      </Body>
    )
  }

  return (
    <div>
      {alertData !== null && (
        <Alert variant={alertData?.variant} onClose={() => setAlertData(null)} dismissible>
          {alertData?.text}
        </Alert>
      )}
      <StyledTabs defaultActiveKey="home">
        <Tab eventKey="home" title="Home">
          <Body>
            <Container fluid style={{ maxWidth: '1400px' }}>
              <Row className="mb-4">
                <Col xs={{ span: 'auto' }}>
                  <ConnectWalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
                </Col>
                <Col xs={{ span: 2 }}>{connectedWallet && <SignInButton token={tokenBDApi} loadToken={loadTokenBDApi} apiName="BD" />}</Col>
                <Col xs={{ span: 2 }}>{connectedWallet && <SignInButton token={tokenDM} loadToken={loadTokenDM} apiName="DM" />}</Col>
                <Col xs={{ span: 2, offset: 10 }}>{connectedWallet}</Col>
              </Row>
              {tokenBDApi && (
                <>
                  <Row>
                    <Col>
                      {seeds.filter((x) => x.unlocked).length > 0 && (
                        <AddEditOldDealModal
                          onSave={createNewDeal}
                          seeds={seeds.filter((x) => x.unlocked)}
                          show={show}
                          handleClose={() => setShow(false)}
                          handleShow={() => setShow(true)}
                          setAlertData={setAlertData}
                        />
                      )}
                    </Col>
                    <Col>{tokenDM && <SeedModal createNewSeed={createNewSeed} />}</Col>
                    <Col>
                      {seeds.filter((x) => x.unlocked).length > 0 ? (
                        <WalletModal createNewWallet={createNewWallet} seeds={seeds.filter((x) => x.unlocked)} />
                      ) : (
                        <span>NO UNLOCKED SEEDS</span>
                      )}
                    </Col>
                    <Col>
                      {assignableWallets.length > 0 && assignableDeals.length > 0 && (
                        <AssignWalletModal wallets={assignableWallets} deals={assignableDeals} assignWallet={onAssignWallet} />
                      )}
                    </Col>
                  </Row>
                  <br></br>
                  <br></br>
                  {!activeDeal ? (
                    <Row
                      style={{
                        overflowX: 'auto'
                      }}
                    >
                      <Col>
                        <Table variant="dark" responsive>
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Draft</th>
                              <th scope="col">Image</th>
                              <th scope="col">Token Address</th>
                              <th scope="col">Claimer</th>
                              <th scope="col">Chain</th>
                              <th scope="col">Deal Wallet</th>
                              <th scope="col">Recieve Wallet</th>
                              <th scope="col">Allocation Model</th>
                              <th scope="col">Total Amount</th>
                              <th scope="col">Distribution Model</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {dealData.map((deal) => {
                              return (
                                <tr key={deal.id.toString()}>
                                  {/* <th scope="row">{i.toString()}</th> */}
                                  <td
                                    onClick={() => {
                                      setActiveDeal(deal)
                                    }}
                                  >
                                    {deal.id.toString()}
                                  </td>
                                  <td>{deal.name}</td>
                                  <td>{deal.isDraft ? 'Draft' : 'Published'}</td>
                                  <td>
                                    <Image alt={deal.name} src={deal.imageUrl} />
                                  </td>
                                  <td>{shortEthereumAddress(deal.tokenAddress)}</td>
                                  <td>
                                    {deal.claimers[0] && (
                                      <a
                                        href={`${deal.explorerUrl}/address/${deal.claimers[0].address}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {shortEthereumAddress(deal.claimers[0].address)}
                                      </a>
                                    )}
                                  </td>
                                  <td>{deal.blockchainName}</td>
                                  <td>
                                    {deal.dealWallet && (
                                      <a href={`https://etherscan.io/address/${deal.dealWallet}`} target="_blank" rel="noopener noreferrer">
                                        {shortEthereumAddress(deal.dealWallet)}
                                      </a>
                                    )}
                                  </td>
                                  <td>
                                    {deal.recieveWallet && (
                                      <a
                                        href={`https://etherscan.io/address/${deal.recieveWallet}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {shortEthereumAddress(deal.recieveWallet)}
                                      </a>
                                    )}
                                  </td>
                                  <td>{deal.allocationModel}</td>
                                  <td>{deal.totalAmount}</td>
                                  <td>{deal.distributionModel}</td>
                                  <td>
                                    {seeds.filter((x) => x.unlocked).length > 0 && (
                                      <AddEditOldDealModal
                                        onSave={updateOldDealDetails}
                                        seeds={seeds.filter((x) => x.unlocked)}
                                        show={dealToEdit?.id === deal.id}
                                        handleClose={() => setDealToEdit(null)}
                                        handleShow={() => setDealToEdit(deal)}
                                        setAlertData={setAlertData}
                                        dealToEdit={dealToEdit}
                                      />
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <Row className="mb-4">
                        <Col xs={'auto'}>
                          <StyledButton onClick={() => setActiveDeal(null)}>Show all</StyledButton>
                        </Col>
                        <Col xs={'auto'}>
                          <StyledButton onClick={deleteDeal}>Delete</StyledButton>
                        </Col>
                        <Col xs={'auto'}>
                          <StyledButton onClick={() => checkBdtBalances(activeDeal)}>Check for sinners</StyledButton>
                        </Col>
                        <Col xs={'auto'}>
                          <StyledButton onClick={() => updateDeal(activeDeal, contributions)}>Update</StyledButton>
                        </Col>
                        <Col xs={'auto'}>
                          <StyledButton onClick={() => publishDeal(activeDeal)}>
                            {activeDeal.isDraft ? 'Publish deal' : 'Unpublish deal'}
                          </StyledButton>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          overflowX: 'auto'
                        }}
                      >
                        <Col>
                          <Table variant="dark">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">wallet</th>
                                <th scope="col">amount</th>
                                <th scope="col">tx hash</th>
                                <th scope="col">valid</th>
                                <th scope="col">reason</th>
                              </tr>
                            </thead>
                            <tbody>
                              {contributions.map((contribution) => (
                                <ContributionRow
                                  key={contribution.id}
                                  contribution={contribution}
                                  updateContribution={updateContribution}
                                />
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  )}{' '}
                </>
              )}
            </Container>
          </Body>
        </Tab>
        <Tab eventKey="walletsTable" title="Wallets Table" disabled={!tokenDM}>
          <Body>
            <Container>
              <WalletsTable wallets={wallets} />
            </Container>
          </Body>
        </Tab>
        <Tab eventKey="seedsTable" title="Unlock Seeds & Wallets" disabled={!tokenDM}>
          <Body>
            <Container>
              <SeedsAndWalletsTable
                seeds={seeds}
                encryptedWallets={encryptedWallets}
                setAlertData={setAlertData}
                loadSeeds={loadSeeds}
                loadEncryptedWallets={loadEncryptedWallets}
              />
            </Container>
          </Body>
        </Tab>
        <Tab eventKey="assignedwallets" title="Assigned Wallets" disabled={!tokenDM}>
          <Body>
            <Container>{tokenDM ? <AssignedWalletsTable /> : <div>SIGN IN TO DM</div>}</Container>
          </Body>
        </Tab>
        <Tab eventKey="joblogs" title="Job Logs" disabled={!tokenDM}>
          <Body>
            <Container>{tokenDM ? <JobsTable /> : <div>SIGN IN TO DM</div>}</Container>
          </Body>
        </Tab>
      </StyledTabs>
    </div>
  )
}

export default App
