import React from "react";
import { Table } from "react-bootstrap";

const WalletsTable = ({ wallets }) => {
  return (
    <Table variant="dark">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Name</th>
          <th scope="col">Seed</th>
          <th scope="col">Address</th>
          <th scope="col">Path</th>
          <th scope="col">Claimer</th>
        </tr>
      </thead>
      <tbody>
        {wallets?.map((wallet) => (
          <tr key={wallet.id}>
            <td>{wallet.id}</td>
            <td>{wallet.name}</td>
            <td>{wallet.seedName}</td>
            <td>{wallet.address}</td>
            <td>{wallet.path}</td>
            <td>{wallet.claimer || '/'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default WalletsTable;
