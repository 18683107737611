import React, {useEffect, useState} from "react";
import { Table } from "react-bootstrap";
import {getAssignedWallets} from '../services/apiService'

const AssignedWalletsTable = () => {
  const [loading, setLoading] = useState(true)
  const [assignedWallets, setAssignedWallets] = useState([])

  useEffect(() => {
    const initialLoad = async () => {
      const response = await getAssignedWallets()
      console.log('initialLoad', response.data)
      setAssignedWallets(response.data)
      setLoading(false)
    }
    initialLoad()
  }, [])
  return loading ? (<div>SOONISH</div>)
  : (
    <Table variant="dark">
      <thead>
        <tr>
          <th scope="col">Deal</th>
          <th scope="col">Seed</th>
          <th scope="col">Wallet</th>
          <th scope="col">Claimer</th>
          <th scope="col">Chain</th>
        </tr>
      </thead>
      <tbody>
        {assignedWallets.map((wallet) => (
          <tr key={`${wallet.deal}-${wallet.wallet}-${wallet.chainId}`}>
            <td>{wallet.deal}</td>
            <td>{wallet.seedName}</td>
            <td>{wallet.wallet}</td>
            <td>{wallet.claimer}</td>
            <td>{wallet.chainName}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AssignedWalletsTable;
