import { Modal, Form } from "react-bootstrap";
import { StyledButton } from "./index";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getSeedWallets } from "../services/apiService"

export const AbsoluteDiv = styled("div")`
  position: absolute;
  height: 38px;
  bottom: 0;
  left: 0;
  background: #ccc;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: .25rem;
`;

export const PathInput = styled(Form.Control)`
  padding-left: 120px;
`;

function WalletModal({ createNewWallet, seeds }) {
  const [show, setShow] = useState(false);
  const [wallet, setWallet] = useState({ seedId: seeds[0].id });
  const [selectedSeedWallets, setSelectedSeedWallets] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onInputChange = (e) => {
    const { value, name } = e.target;
    console.log("oninputchange", value, name);

    setWallet({ ...wallet, [name]: value });
  };

  useEffect(() => {
    const loadSeedWallets = async () => {
      const seedWallets = await getSeedWallets('trezor', wallet.seedId)
      setSelectedSeedWallets(seedWallets.data)
      setWallet({...wallet, path: seedWallets.data[0].path})
    }

    if (wallet.seedId) {
      loadSeedWallets()
    }
    // eslint-disable-next-line
  }, [wallet.seedId])

  return (
    <>
      <StyledButton variant="primary" onClick={handleShow}>
        New Wallet
      </StyledButton>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                onChange={onInputChange}
                type="text"
                placeholder="Enter name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Seed</Form.Label>
              <Form.Select
                value={wallet.seedId}
                onChange={onInputChange}
                aria-label="Default select example"
                name="seedId"
              >
                {seeds.map((seed) => (
                  <option key={seed.id.toString()} value={seed.id}>
                    {seed.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Wallet</Form.Label>
              <Form.Select
                value={wallet.path}
                onChange={onInputChange}
                aria-label="Default select example"
                name="path"
              >
                {selectedSeedWallets.map((wallet) => (
                  <option key={wallet.path} value={wallet.path}>
                    {wallet.path} - {wallet.address}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {/* <PathHolder className="mb-3">
              <Form.Label>Path</Form.Label>
              <AbsoluteDiv>m/44'/60'/0'/0/</AbsoluteDiv>
              <PathInput
                name="path"
                onChange={onInputChange}
                type="text"
                placeholder="Enter path"
              />
            </PathHolder> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <StyledButton variant="secondary" onClick={handleClose}>
            Close
          </StyledButton>
          <StyledButton
            variant="primary"
            onClick={() => {
              createNewWallet(wallet);
            }}
          >
            Save Changes
          </StyledButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WalletModal;
