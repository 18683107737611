import React from "react";

const ContributionRow = ({ contribution, updateContribution }) => {
  const toggleValid = (event) => {
    const newContribution = { ...contribution, valid: event.target.checked };
    updateContribution(newContribution);
  };

  const onInputChange = (e) => {
    const { value, name } = e.target;
    contribution[name] = value;
    updateContribution(contribution);
  };

  return (
    <tr>
      <td>{contribution.id.toString()}</td>
      <td>
        <a
          href={`https://etherscan.io/token/0x4Efe8665e564bF454cCF5C90Ee16817F7485d5Cf?a=${contribution.wallet}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {contribution.wallet}
        </a>
      </td>
      <td>
        <input
          type="text"
          name="amount"
          value={contribution.amount}
          onChange={onInputChange}
        />
      </td>
      <td>
        {contribution.txHash && (<a
          href={`https://etherscan.io/tx/${contribution.txHash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${contribution.txHash.substring(
            0,
            5
          )}...${contribution.txHash.substring(
            contribution.txHash.length - 6,
            contribution.txHash.length - 1
          )}`}
        </a>)}
      </td>
      <td>
        <input
          checked={contribution.valid}
          onChange={toggleValid}
          name="reason"
          type="checkbox"
        ></input>
      </td>
      <td>
        <input
          value={contribution.reason || ""}
          onChange={onInputChange}
          name="reason"
          type="input"
        ></input>
      </td>
    </tr>
  );
};

export default React.memo(ContributionRow);
