import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getJobs } from '../services/apiService'
import { StyledButton } from "./index";

const JobsTable = () => {
  const [filter, setFilter] = useState('');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true)

  const loadJobs = async () => {
    setLoading(true);
    const response = await getJobs(filter)
    console.log('initialLoad', response.data)
    setJobs(response.data)
    setLoading(false)
  }

  useEffect(() => {
    loadJobs()
    // eslint-disable-next-line
  }, [])

  return loading ? (<div>LOADING</div>)
  : (
    <div>
      <div>
        <input name="filter" onChange={(e) => { setFilter(e.target.value) }} value={filter}></input>
        <StyledButton variant="primary" onClick={loadJobs}>
          Apply Filter
        </StyledButton>
      </div>
      <Table variant="dark" responsive>
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Name</th>
            <th scope="col">Phase</th>
            <th scope="col">Log</th>
            <th scope="col">Duration</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job, i) => (
            <tr key={i.toString()}>
              <td>{job.createdAt}</td>
              <td>{job.name}</td>
              <td>{job.phase}</td>
              <td>{job.log}</td>
              <td>{job.duration}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default JobsTable;
